/**
 * Test email for validity
 * @param {string} email 
 * @returns {boolean}
 */
export const testEmail = (email) => {
  // eslint-disable-next-line
  const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(email);
};

/**
 * Test phone for validity
 * @param {string} phone 
 * @returns {boolean}
 */
export const testPhone = (phone) => {
  const phoneRegEx = /^[0-9]{10}$/;
  return phoneRegEx.test(phone);
};

/**
 * Test image file for validity
 * @param {File} imageFile 
 * @returns {boolean}
 */
export const testImageFile = (imageFile) => {
  const fileType = imageFile?.type;

  // Is this a file with a file type?
  if (!fileType) {
    return false;
  }

  const parts = fileType.split('/');

  // Is this an image file?
  if (parts[0] !== 'image') {
    return false;
  }

  // Is this a PNG or Jpeg?
  if (!(parts[1] === 'png' || parts[1] === 'jpeg')) {
    return false;
  }
  
  return true;
};
