import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { storeInfo } from '../helpers/data';

const StoreNav = (props) => {
  const { display, visible, navRef } = props;
  const history = useHistory();
  const routerLocation = useLocation();
  const analytics = getAnalytics();

  const handleStoreButton = (storeId, dispensaryType, storeName) => {
    history.push(`/store/${storeId}/menu/${dispensaryType.substring(0, 3)}`);
    logEvent(analytics, `nav_to_store_${dispensaryType}_${storeName.toLowerCase()}`)
  }

  const navStyle = `StoreNav${display ? ' show' : ''}${visible ? ' visible' : ''}`;

  return (
    <div className={navStyle} ref={navRef}>
      {storeInfo.map((location) => {
        const { name, menus, id } = location;

        const menuButtons = menus.map((menu) => {
          const currentPath = routerLocation.pathname;
          const isSelected = currentPath.includes(id) && currentPath.includes(menu.dispensaryType.substring(0, 3));
          if (menu.id.length) { 
            return (
              <button 
                key={menu.id}
                type="button"
                className={isSelected ? 'selected' : ''}
                onClick={() => handleStoreButton(id, menu.dispensaryType, location.name)}
              >
                {name}
                &nbsp;-&nbsp;
                <span className="typeLabel">
                  {menu.dispensaryType.substring(0, 3)}
                </span>
              </button>
            );
          }
        });

        return menuButtons;
      })}
    </div>
  );
};

export default StoreNav;
