import React, { useRef, useState } from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useHistory } from 'react-router-dom';

// Import components
import Screening from '../screening/Screening';
import FAQ from './sections/FAQ';
import Intro from './sections/Intro';
import Perks from './sections/Perks';
import SignUp from './sections/SignUp';
import RIFooter from './sections/Footer';
import Complete from '../complete/Complete';

const Delivery = () => {
  const [showScreening, setShowScreening] = useState(true);
  const [showComplete, setShowComplete] = useState(false);
  const analytics = getAnalytics();
  const scrollRef = useRef();

  const history = useHistory();

  // Determine if we're accessing the RI delivery area via an internal link
  const internalReferral = history.location.state?.internalReferral;

  // Build handlers for buttons
  const screeningComplete = (useType) => {
    // useType is 'med' or 'rec'
    logEvent(analytics, `delivery_screening_complete_${useType}`);
    if (useType === 'rec') {
      setShowScreening(false);
      history.push('/store/ri002');
      return;
    }
    // If med, simply close the screening
    setShowScreening(false);
  }

  const formSubmissionSuccess = () => {
    setShowComplete(true);
  }

  const handleDone = () => {
    setShowComplete(false);
    history.push('/');
  }

  return (
    <>
      <Screening
        visible={showScreening}
        screeningComplete={screeningComplete}
        ageGateBypass={!!internalReferral}
      />
      <Complete 
        visible={showComplete}
        handleDone={handleDone}
      />
      <main className="Delivery">
        <Intro scrollRef={scrollRef} />
        <Perks scrollRef={scrollRef} />
        <SignUp 
          formSubmissionSuccess={formSubmissionSuccess}
        />
        <FAQ />
        <RIFooter />
      </main>
    </>
  );
};

export default Delivery;