export const quizTransitionDelay = Math.floor(1000/3);

export const quizData = [
  {
    question: "What is your perspective on consuming cannabis?",
    answers: [
      "It's part of my routine",
      "I'm nervous to try it",
      "It doesn't do too much for me",
      "I'm curious about it"
    ],
    required: false,
    multiple: false,
    theme: "plant1"
  },
  {
    question: "When was the last time you consumed cannabis?",
    answers: [
      "Never/Over a year",
      "In the past week",
      "In the past month",
      "In the past year"
    ],
    required: false,
    multiple: false,
    theme: "plant2"
  },
  {
    question: "When would you prefer to consume your cannabis?",
    answers: [
      "Daytime", "Evening", "In my free time", "Special occasions"
    ],
    required: false,
    multiple: false,
    theme: "day"
  },
  {
    question: "How many times per week do you plan to consume?",
    answers: [
      "1 to 3 times", "3 to 6 times", "Daily"
    ],
    required: false,
    multiple: false,
    theme: "calendar"
  },
  {
    question: "How long would you prefer the psychoactive effects to last?",
    answers: [
      "Non-psychoactive", "Less than 3 hours", "More than 3 hours"
    ],
    required: false,
    multiple: false,
    theme: "time"
  },
  {
    question: "Which consumption method sounds the most appealing to you?",
    answers: [
      "Smoking", "Vaping", "Edible", "Tincture"
    ],
    required: false,
    multiple: false,
    theme: "drugs"
  },
  {
    question: "Are you using cannabis for any of these specific reasons?",
    answers: [
      "Pain", "Anxiety", "Sleep", "Relaxation/fun"
    ],
    required: false,
    multiple: true,
    theme: "use"
  },
  {
    question: "When consuming, what activity most closely resembles how you'd spend time?",
    answers: [
      "Sleep/Relaxing", "Reading / TV", "Something social", "Exercise"
    ],
    required: false,
    multiple: false,
    theme: "relaxation"
  },
  {
    question: "Do you prefer being social when consuming cannabis?",
    answers: [
      "I prefer being social",
      "I prefer being alone"
    ],
    required: false,
    multiple: false,
    theme: "plant3"
  },
  {
    question: "After spending time in a large group, I feel...",
    answers: [
      "Energized",
      "Tired",
      "Anxious"
    ],
    required: false,
    multiple: false,
    theme: "drama"
  },
  {
    question: "When going out to eat, what do you prefer?",
    answers: [
      "Trying something new",
      "My favorite spot",
      "Friend's recommendation"
    ],
    required: false,
    multiple: false,
    theme: "food"
  },
  {
    question: "I am comfortable with being out of control.",
    answers: [
      "Agree",
      "Neutral",
      "Disagree",
      "Strongly disagree"
    ],
    required: false,
    multiple: false,
    theme: "control"
  },
  {
    question: "I tend to worry too much.",
    answers: [
      "Agree",
      "Neutral",
      "Disagree",
      "Strongly disagree"
    ],
    required: false,
    multiple: false,
    theme: "spools"
  },
  {
    question: "What is your favorite candle scent out of the following options?",
    answers: [
      "Pine", "Floral", "Sweet", "Citrus"
    ],
    required: false,
    multiple: false,
    theme: "scents"
  },
  {
    question: "What is your preferred caffeinated drink?",
    answers: [
      "Espresso",
      "Coffee",
      "Herbal tea"
    ],
    required: false,
    multiple: false,
    theme: "drinks"
  }
];

export const quizData2 = [
  {
    id: "frequency",
    type: "multipleChoice",
    question: "How often do you consume cannabis?",
    info: "We ask this question to help determine the strength/intensity that may be best for you. Consuming cannabis regularly will slowly build a tolerance over time. We always recommend starting with a low-dose product and slowly increasing the dosage from there if you feel comfortable.",
    answers: [
      {
        id: 1,
        type: "lowDose",
        text: ["A year ago / Never"],
      },
      {
        id: 2,
        type: "lowDose",
        text: ["A few times per year"],
      },
      {
        id: 3,
        type: "highDose",
        text: ["A few times per month"],
      },
      {
        id: 4,
        type: "highDose",
        text: ["Regularly / Every day"],
      },
    ]
  },
  {
    id: "purpose",
    type: "multipleChoice",
    question: "What are you seeking from cannabis?",
    info: "Different products may result in different effects. One strain may cause you to feel relaxed and sleepy, while another may cause feelings of excitement and creativity. Our in-store guides can recommend different strains depending on what effect you are trying to achieve.",
    answers: [
      {
        id: 1,
        type: "relaxing",
        text: ["Pain relief"],
      },
      {
        id: 2,
        type: "relaxing",
        text: ["Anxiety relief"],
      },
      {
        id: 3,
        type: "relaxing",
        text: ["Sleep aid"],
      },
      {
        id: 4,
        type: "relaxing",
        text: ["Relaxation"],
      },
      {
        id: 5,
        type: "energizing",
        text: ["Creative boost"],
      },
      {
        id: 6,
        type: "energizing",
        text: ["Help with focus"],
      },
      {
        id: 7,
        type: "energizing",
        text: ["Exercise / Workout"],
      },
      {
        id: 8,
        type: "energizing",
        text: ["Fun / Recreation"],
      },
    ]
  },
  {
    id: "method",
    type: "multipleChoice",
    question: "What is your preferred consumption method?",
    info: "There are several different ways to consume cannabis and each will have a slightly different effect in terms of feeling and duration. Our in-store guides can help you find a consumption method that works best for your needs.",
    answers: [
      {
        id: 1,
        type: "smoking",
        text: ["Smoking", "Vape pen", "Concentrate"],
      },
      {
        id: 2,
        type: "edibles",
        text: ["Edible", "Beverage", "Tincture"],
      },
    ]
  },
  {
    id: "barriers",
    type: "multipleChoice",
    question: "What has stopped you from purchasing cannabis?",
    info: "Shopping for cannabis can be very confusing and intimidating, especially for beginners! We're here to create a worry-free shopping experience.",
    answers: [
      {
        id: 1,
        type: "legal",
        text: ["Access to legal cannabis"],
      },
      {
        id: 2,
        type: "fear",
        text: ["Fear of a bad experience"],
      },
      {
        id: 3,
        type: "stigma",
        text: ["Society's stigma"],
      },
      {
        id: 4,
        type: "unsure",
        text: ["Not sure where to begin"],
      },
    ]
  },
  {
    id: "activity",
    type: "openText",
    question: "What activities do you plan on doing while consuming cannabis? (example: reading, watching movies, sleeping)",
    info: "Knowing your intended use will help inform our product recommendations.",
    answers: [],
  },
  {
    id: "experience",
    type: "openText",
    question: "Describe your previous experiences consuming cannabis (both positive and negative).",
    info: "Knowing about your previous cannabis experiences will help our in-store guides in determining products may work best for you, along with what can be done to avoid any negative experiences you may have experienced in the past.",
    answers: [],
  },
];

// Build required format for submitting responses to serverless functions
export const prepareQuizResponses = (quizResponses) => {
  const { multipleChoice, activity, experience } = quizResponses;

  // Process multiple choice answers
  const processed = multipleChoice.map((question) => {
    const { id, answers } = question;
    let text = "";
    const answerObj = answers[0]; // We're only allowing a single answer per multiple choice question
    answerObj?.text?.forEach((t, i) => {
      text = text + `${i > 0 ? "; " : ""}${t}`;
    });
    return ({
      id, text
    });
  });

  // Add open text answers
  processed.push({
    id: "activity",
    text: activity.trim()
  },
  {
    id: "experience",
    text: experience.trim()
  });

  // Return completed array
  return processed;
};

export const quizResultsMessage1 = "Thank you! Below you will find information and recommendations which may help guide you along your cannabis journey. Once you've had a chance to review, stop by a Sweetspot and one of our cannabis experts will be happy to guide you towards products that match your quiz results.";
export const quizResultsMessage2 = "We've also emailed you a coupon that can be applied towards your next purchase for 20% off!";

export const quizResults = {
  relaxing: {
    title: "Relaxing",
    text: "Based on your responses, it seems like you are looking for something that is more relaxing. At Sweetspot, we put a lot of emphasis on terpene profiles which have a large role in determining what kind of experience you may have. So, we would suggest strains that are high in Myrcene, Caryophyllene and Linalool. These terpenes are generally associated with calming and soothing effects that are perfect for those who are looking to unwind after a long day or have trouble falling asleep.",
  },
  energizing: {
    title: "Energizing",
    text: "Based on your responses, it seems like you are looking for something that is more uplifting. At Sweetspot, we put a lot of emphasis on terpene profiles which have a large role in determining what kind of experience you may have. So, we would suggest strains that are high in Limonene, Terpinolene and Pinene. These are terpenes that are generally associated with energizing effects and help us avoid feeling sedated or sleepy.",
  },
  lowDose: {
    title: "Low-Mid Dose",
    text: "Let's start slowly and keep the THC levels at a low-dose. For an infrequent or new consumer, it is always better to choose a milder option. Small doses of THC can be just as beneficial as higher doses, so don't be concerned with missing out on any benefits. We suggest sticking to edibles that are less than 5mg of THC or flower strains that are in our low bucket, which is under 20% THC.",
  },
  highDose: {
    title: "Mid-High Dose",
    text: "It seems like you may enjoy the psychoactive effects from cannabis and we think increasing the dosage in a manageable way is a good idea. Let's be sure to avoid an experience that is too intense. We suggest starting with the medium THC bucket with ingestibles that have no more than 10mg of THC and flower that is between 22% and 25% THC. Let's take our time and make sure we find the perfect level of THC dosage for your comfort level. It is always easier to take more if needed.",
  },
  smoking: {
    title: "Smoking/Vaping",
    text: "Smoking or vaping is a great option for those looking for a quick onset of effects and a shorter duration. Effect from from inhaled cannabis will typically begin within 15 minutes and may last an hour to three hours and taper off. With regular use, you may start to feel the effects minimize over time. If this happens, you may find it beneficial to increase the dosage to maintain your desired effect. Let's go slow and find the right starting point.",
  },
  edibles: {
    title: "Edibles",
    text: "Ingesting cannabis is a great way to get long lasting effects, much more so than smoking or vaping. This method will have effects that may last up to 8 hours, but generally take a much longer time to feel the onset when compared to smoking. Most people start feeling effects within 45 minutes, but it can take up to 2 hours for the effects to fully kick in. It is always best when starting with ingestibles to take one dose and wait at least 4 hours to ensure that the effects have fully set in before taking more. Let's go slow and track results.",
  },
};

// Given an array of multiple choice quiz results, determine which quiz results will be shown
export const determineQuizResults = (questions) => {
  const answerTypes = [];
  
  questions.forEach((question) => {
    const answers = question.answers ?? [];
    answers.forEach((answer) => {
      if (answer.type) {
        answerTypes.push(answer.type);
      }
    });
  });

  const results = [];

  if (answerTypes.includes("relaxing")) {
    results.push({
      id: "relaxing",
      ...quizResults.relaxing,
    });
  }
  if (answerTypes.includes("energizing")) {
    results.push({
      id: "energizing",
      ...quizResults.energizing,
    });
  }
  if (answerTypes.includes("lowDose")) {
    results.push({
      id: "lowDose",
      ...quizResults.lowDose,
    });
  }
  if (answerTypes.includes("highDose")) {
    results.push({
      id: "highDose",
      ...quizResults.highDose,
    });
  }
  if (answerTypes.includes("smoking")) {
    results.push({
      id: "smoking",
      ...quizResults.smoking,
    });
  }
  if (answerTypes.includes("edibles")) {
    results.push({
      id: "edibles",
      ...quizResults.edibles,
    });
  }

  return results;
};
