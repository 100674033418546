import { useEffect, useState } from "react"

export const useTabs = (items) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = items[activeTabIndex];
  const isActiveTab = (index) => index === activeTabIndex;

  // Reset to first tab when the source content changes
  useEffect(() => {
    setActiveTabIndex(0);
  }, [items])

  return {
    activeTab, setActiveTabIndex, isActiveTab
  }
}