import LogoIconDarkGreenSpinner from '../assets/images/logo-icon-dark-green-spinner.png';
import LogoIconPaleGreenSpinner from '../assets/images/logo-icon-pale-green-spinner.png';

/**
 * Renders a spinning logo that will fill its container
 * Takes an optional theme ('light' or 'dark')
 * Dark theme is default
 */
const LogoSpinner = (props) => {
  const { theme } = props;

  let icon = LogoIconDarkGreenSpinner;

  if (theme === 'light') {
    icon = LogoIconPaleGreenSpinner;
  }

  return (
    <img src={icon} style={{height: '100%'}} className="logoSpinner" alt="Spinner" />
  );
};

export default LogoSpinner;