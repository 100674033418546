import { getStorage, ref, uploadBytes } from "firebase/storage";

/**
 * Upload a file to Firebase Cloud Storage
 */
export const fileUpload = ({
  path,
  fileName,
  file
}) => {
  return new Promise((resolve, reject) => {
    if (!path || !fileName || !file) {
      throw new Error('Missing required parameters.');
    }

    // Create ref to new file in Firebase Storage
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${fileName}`);
    
    // Upload file
    uploadBytes(storageRef, file)
    .then((fileSnapshot) => {
      console.log('Uploaded file: ', fileSnapshot);
      const { metadata } = fileSnapshot;

      // Return storage path to file
      resolve(metadata.fullPath);
    })
  });
};
