import React from 'react';
import { useHistory } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

export default function MobileNav(props) {
  const { onButtonPress } = props;

  const history = useHistory();

  const handleMenuButton = (id) => {
    onButtonPress();
    const section = document.getElementById(id);
    const rect = section.getBoundingClientRect();
    const yDistance = rect.top; // From the top of the viewport
    animateScroll.scrollMore(yDistance);
  }

    // eslint-disable-next-line
  const handleFranchiseButton = () => {
    onButtonPress();
    history.push('/franchising');
  }

  return (
    <nav className="MobileNav">
      <button type="button" className="option" onClick={() => handleMenuButton('sustainability')}>
        Sustainability
      </button>
      <button type="button" className="option" onClick={() => handleMenuButton('locations')}>
        Stores
      </button>
      <button type="button" className="option" onClick={() => handleMenuButton('products')}>
        Products
      </button>
      <button type="button" className="option italic" onClick={handleFranchiseButton}>
        Franchise
      </button>
    </nav>
  );
}
