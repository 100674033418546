import React, { useState } from 'react';

// Import Ant Design
// https://ant.design/
import { Modal } from 'antd';

// Import components
import Age from '../../gate/Age';
import DeliveryMethod from './delivery-method/DeliveryMethod';
import MedicalCard from './medical-card/MedicalCard';
import Residency from './residency/Residency';
import { getAnalytics, logEvent } from 'firebase/analytics';

const Screening = (props) => {
  const { visible, screeningComplete, ageGateBypass } = props;
  const analytics = getAnalytics();
  const [screenName, setScreenName] = useState(ageGateBypass ? 'residency' : 'age'); // age | deliveryMethod | residency | medical-card

  // This is only used for the duplicate RI age gate
  const handleAgeGateYes = () => {
    logEvent(analytics, "age_gate_yes");
    setScreenName('deliveryMethod');
  }

  const handleDeliveryChoice = (choice) => {
    // Choice is 'med' or 'rec'
    logEvent(analytics, `delivery_choice_${choice}`);
    if (choice === 'med') {
      setScreenName('residency');
      return;
    }
    screeningComplete('rec');
    logEvent(analytics, `delivery_screening_complete_rec`);
  }

  const handleResidencyChoice = (bool) => {
    if (bool === true) {
      setScreenName('medicalCard');
    }
  }

  const handleMedicalCardChoice = (bool) => {
    if (bool === true) {
      screeningComplete('med');
      logEvent(analytics, `delivery_screening_complete_med`);
    }
  }

  // Build modal renderer
  const modalRender = () => (
    <div className="gateWrapper">
      <div className="gateContainer">
        <header>
          <span className="image">
            <img 
              src={require('../../../assets/images/logo-text-dark-green.png')} 
              alt="Sweetspot"
            />
          </span>
        </header>
        { screenName === 'age' && (
          <Age
            handleContinue={handleAgeGateYes}
          />
        )}
        { screenName === 'deliveryMethod' && (
          <DeliveryMethod
            handleContinue={handleDeliveryChoice}
          />
        )}
        { screenName === 'residency' && (
          <Residency
            handleContinue={handleResidencyChoice}
          />
        )}
        { screenName === 'medicalCard' && (
          <MedicalCard
            handleContinue={handleMedicalCardChoice}
          />
        )}
      </div>
    </div>
  );

  return (
    <Modal
      modalRender={modalRender}
      open={visible}
      maskClosable={false}
      centered
      width={'90%'}
      height={'90%'}
      style={{ paddingBottom: 0 }}
    />
  );
};

export default Screening;