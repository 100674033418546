import React, {useEffect, useState} from 'react';

import { productData } from '../../../helpers/data';
import ProductPanelContent from '../../../shared/ProductPanelContent';
import ProductInfoContent from '../../../shared/ProductInfoContent';

import ChevronDownGreen from '../../../assets/images/chevron-down-green.png';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Collapse } from 'antd';

const { Panel } = Collapse;

const ProductChevron = (props) => {
  const { panelProps } = props;
  const { isActive } = panelProps;
  const iconClassName = `panelIcon${isActive ? ' rotated' : ''}`
  return (
    <img 
      src={ChevronDownGreen}
      alt="Chevron down green"
      className={iconClassName}
    />
  );
}

const ProductTitle = (props) => {
  const { text } = props;
  return (
    <span className="title">
      {text}
    </span>
  );
};

export default function Products() {
  const [selectedProduct, setSelectedProduct] = useState('');

  // Set the first product as selected on load
  useEffect(() => {
    const firstProduct = productData[0];
    if (firstProduct) {
      setSelectedProduct(firstProduct.id ?? '');
    }
  }, []);

  const products = productData.map((data, index) => {
    const { id, name, comingSoon } = data;
  
    const isLast = index === productData.length - 1;
    const panelClassName = `product${comingSoon ? ' comingSoon' : ''}${isLast ? ' noBottomBorder' : ''}`;
  
    if (comingSoon) {
      return (
        <div 
          key={id}
          className={panelClassName}
        >
          <span className="titleText">
            {name}
          </span>
          <span className="comingSoonText">
            &#91;coming soon&#93;
          </span>
        </div>
      );
    } else {
      return (
        <Panel
          key={id}
          header={<ProductTitle text={name} />}
          className={panelClassName}
        >
          <ProductPanelContent productData={data} />
        </Panel>
      );
    }
  });

  const analytics = getAnalytics();
  const handleProductButton = (id, comingSoon) => {
    if (comingSoon) {
      logEvent(analytics, "products_coming_soon_pressed");
      return;
    }
    logEvent(analytics, `products_${id}_pressed`);
    setSelectedProduct(id);
  }

  const productButtons = productData.map((data, index) => {
    const {id, name, comingSoon} = data;
    const isLast = (index === productData.length - 1);
    const buttonClassName = `button${isLast ? ' isLast' : ''}${id === selectedProduct ? ' selected' : ''}${comingSoon ? ' disabled' : ''}`;

    return (
      <button key={id} type="button" className={buttonClassName} onClick={() => handleProductButton(id, comingSoon)}>
        <span className="name">
          {name}
        </span>
        {comingSoon && (
          <span className="comingSoon">
            [coming soon]
          </span>
        )}
      </button>
    );
  });

  const selectedProductData = productData.find((data) => {
    return data.id === selectedProduct;
  });

  return (
    <section className="Products">
      <div className="contentWrapper">
        <div className="mobileContent">
          <Collapse 
            ghost
            accordion
            expandIcon={(panelProps) => <ProductChevron panelProps={panelProps} />}
            expandIconPosition="end"
            bordered={false}
            className="productsAntCollapse"
          >
            {products}
          </Collapse>
        </div>
        <div className="desktopContent">
          <div className="productButtons">
            {productButtons}
          </div>
          <ProductInfoContent
            productData={selectedProductData}
          />
        </div>
      </div>
    </section>
  );
}
