import React from 'react';

// Import Ant Design
// https://ant.design/
import { Modal } from 'antd';

const Complete = (props) => {
  const { visible, handleDone } = props;

  // Build modal renderer
  const modalRender = () => (
    <div className="gateWrapper">
      <div className="gateContainer">
        <header>
          <span className="image">
            <img 
              src={require('../../../assets/images/logo-text-dark-green.png')} 
              alt="Sweetspot"
            />
          </span>
        </header>
        <h1>
          Thanks!<br />We'll be in touch.
        </h1>
        <div className="screeningButtons">
          <button 
            className="screeningButtonYes noMargin"
            type="button"
            onClick={handleDone}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );

  return ( 
    <Modal
      modalRender={modalRender}
      open={visible}
      maskClosable={false}
      centered
      width={'90%'}
      height={'90%'}
      style={{ paddingBottom: 0 }}
    />
  );
}

export default Complete;